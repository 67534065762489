import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PropTypes from 'prop-types';

function TextSection({
  title,
  subtitle,
  text,
  cta,
  alignment,
  backgroundColor,
  isMarkdown,
}) {
  return (
    <PagepartBase
      title={title}
      subtitle={subtitle}
      text={text}
      cta={cta}
      backgroundColor={backgroundColor.color}
      alignment={alignment}
      isMarkdown={isMarkdown}
      containedSmall
    />
  );
}

TextSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  alignment: PropTypes.string,
  isMarkdown: PropTypes.bool,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default TextSection;
