import Video from '@atoms/Video/Video';
import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PagepartCta from '@molecules/PagepartContent/PagepartCta';
import PropTypes from 'prop-types';

import styles from './VideoSection.module.scss';

function VideoSection({ title, text, cta, video, backgroundColor }) {
  if (!video?.embeddedVideo?.playbackId && !video?.youtubeId) {
    return null;
  }

  const hasContent = title || text || cta?.title;

  return (
    <PagepartBase
      title={title}
      text={text}
      backgroundColor={backgroundColor.color}
      contained
      fullMediaPage
      extraClasses={hasContent && styles.hasContent}
    >
      <Video video={video} />
      <PagepartCta cta={cta} extraClasses={styles.ctaButton} />
    </PagepartBase>
  );
}

VideoSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  video: PropTypes.shape({
    embeddedVideo: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
    youtubeId: PropTypes.string,
    alt: PropTypes.string,
  }),
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default VideoSection;
