import { BG_COLORS, BG_WHITE } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartContent from '@molecules/PagepartContent/PagepartContent';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PagepartBase.module.scss';

export default function PagepartBase({
  title,
  subtitle,
  text,
  content,
  cta,
  ctaAnimation,
  bottomCta,
  CtaIcon,
  contained,
  alignment,
  backgroundColor = BG_WHITE,
  noPadding,
  modifier,
  extraClasses,
  children,
  isMarkdown,
}) {
  return (
    <section
      className={classNames(
        styles.pagepart,
        styles[backgroundColor],
        styles[alignment],
        styles[modifier]
      )}
    >
      <div className={styles.wrapper}>
        <div
          className={classNames(
            styles.container,
            'u-container',
            noPadding && styles.noPadding
          )}
        >
          <div
            className={classNames(
              styles.content,
              styles[alignment],
              contained && styles.contained
            )}
          >
            <PagepartContent
              title={title}
              subtitle={subtitle}
              text={text}
              cta={cta}
              ctaAnimation={ctaAnimation}
              bottomCta={bottomCta}
              CtaIcon={CtaIcon}
              centered={alignment === 'centered'}
              extraClasses={extraClasses}
              isMarkdown={isMarkdown}
            >
              {content}
            </PagepartContent>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}

PagepartBase.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  cta: ctaType,
  ctaAnimation: PropTypes.bool,
  isMarkdown: PropTypes.bool,
  bottomCta: ctaType,
  CtaIcon: PropTypes.func,
  backgroundColor: PropTypes.oneOf(BG_COLORS),
  contained: PropTypes.bool,
  alignment: PropTypes.oneOf(['left', 'centered']),
  noPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  modifier: PropTypes.string,
  extraClasses: PropTypes.string,
};
