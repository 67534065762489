import { ctaType, fieldOfWorkType, storyItemType } from '@lib/proptypes';
import Carousel from '@molecules/Carousel/Carousel';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import StoryItem from '@molecules/StoryItem/StoryItem';
import useStoriesSection from '@utils/useStoriesSection';
import PropTypes from 'prop-types';

function StoriesSection({ title, text, stories, fieldsOfWork, cta }) {
  const { relatedStories } = useStoriesSection(stories, fieldsOfWork);
  if (!relatedStories?.length) return null;

  return (
    <PagepartBase>
      <Carousel
        title={title}
        text={text}
        items={relatedStories}
        cta={cta}
        SlideItem={StoryItem}
      />
    </PagepartBase>
  );
}

StoriesSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  stories: PropTypes.arrayOf(storyItemType),
  fieldsOfWork: PropTypes.arrayOf(fieldOfWorkType),
  cta: ctaType,
};

export default StoriesSection;
