import ContactLink from '@atoms/ContactLink/ContactLink';
import { jobAlertBlockType } from '@lib/proptypes';
import PagepartCta from '@molecules/PagepartContent/PagepartCta';
import classNames from 'classnames';
import Image from 'next/legacy/image';

import styles from './JobAlertCard.module.scss';

function JobAlertCard({
  title,
  text,
  name,
  image,
  phone,
  email,
  firstCta,
  secondCta,
  extraClasses,
  layout,
  modifier,
  abTestData,
}) {
  return (
    <div
      className={classNames(
        styles.card,
        extraClasses,
        styles[layout],
        styles[modifier],
        abTestData?.variantId === 'B' && styles.variantB
      )}
      id={abTestData && `vacancies-job-alert-abtest`}
      data-prepr-variant={abTestData?.variantId}
      data-prepr-variant-key={abTestData?.variantKey}
    >
      <div className={styles.contentContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>

        {(name || phone || email) && (
          <div className={styles.contactContainer}>
            {image && (
              <div className={styles.imageContainer}>
                <Image
                  src={image.url}
                  placeholder="blur"
                  blurDataURL={image.placeholder}
                  alt={name || ''}
                  className={styles.image}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            )}
            <div className={styles.contactInformation}>
              {name && <h4 className={styles.name}>{name}</h4>}

              {(phone || email) && (
                <ul
                  className={classNames(styles.links, 'u-list-clean', 'u-mb-0')}
                >
                  {[phone[0], email[0]]
                    .filter(el => !!el)
                    .map(linkItem => (
                      <li key={linkItem.id}>
                        <ContactLink
                          link={linkItem}
                          extraClasses={styles.link}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.buttonContainer}>
        {firstCta && (
          <PagepartCta
            cta={firstCta}
            extraClasses={styles.button}
            size="small"
            modifierClass={abTestData?.variantId === 'B' ? 'white' : ''}
          />
        )}
        {secondCta && (
          <PagepartCta
            cta={secondCta}
            extraClasses={styles.button}
            modifierClass="secondary"
          />
        )}
      </div>
    </div>
  );
}

JobAlertCard.propTypes = jobAlertBlockType;

export default JobAlertCard;
