import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PagepartContent from '@molecules/PagepartContent/PagepartContent';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './TextMediaWrapper.module.scss';

function TextMediaWrapper({
  title,
  subtitle,
  text,
  cta,
  CtaIcon,
  textLeft,
  children,
  backgroundColor,
  content,
  modifier,
}) {
  return (
    <PagepartBase backgroundColor={backgroundColor}>
      <div
        className={classnames(
          'u-container-grid',
          styles.container,
          !textLeft && styles.textRight,
          modifier && styles[modifier]
        )}
      >
        <div className={styles.content}>
          <PagepartContent
            title={title}
            subtitle={subtitle}
            text={text}
            cta={cta}
            CtaIcon={CtaIcon}
          >
            {content}
          </PagepartContent>
        </div>
        <div className={styles.media}>{children}</div>
      </div>
    </PagepartBase>
  );
}

TextMediaWrapper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  CtaIcon: PropTypes.func,
  textLeft: PropTypes.bool,
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.oneOf(BG_COLORS).isRequired,
  content: PropTypes.node,
  modifier: PropTypes.string,
};

export default TextMediaWrapper;
