import Video from '@atoms/Video/Video';
import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import TextMediaWrapper from '@molecules/TextMediaWrapper/TextMediaWrapper';
import PropTypes from 'prop-types';

function TextVideoSection({
  title,
  subtitle,
  text,
  cta,
  ctaIcon,
  video,
  textLeft,
  backgroundColor,
}) {
  return (
    <TextMediaWrapper
      title={title}
      subtitle={subtitle}
      text={text}
      cta={cta}
      ctaIcon={ctaIcon}
      textLeft={textLeft}
      backgroundColor={backgroundColor.color}
    >
      {(video?.embeddedVideo?.playbackId || video?.youtubeId) && (
        <Video video={video} contained />
      )}
    </TextMediaWrapper>
  );
}

TextVideoSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  ctaIcon: PropTypes.func,
  video: PropTypes.shape({
    embeddedVideo: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
    youtubeId: PropTypes.string,
    alt: PropTypes.string,
  }),
  textLeft: PropTypes.bool,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default TextVideoSection;
