import { BG_COLORS, BG_WHITE } from '@constants';
import { jobAlertBlockType } from '@lib/proptypes';
import recruiterBlockType from '@lib/proptypes/recruiterBlockType';
import JobAlertCard from '@molecules/JobAlertCard/JobAlertCard';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PropTypes from 'prop-types';

function JobAlertSection({
  recruiterBlock,
  jobAlertBlock,
  backgroundColor,
  jobAlertParameters,
}) {
  if (!recruiterBlock[0] || !jobAlertBlock[0]) return null;
  const modifier = backgroundColor.color === BG_WHITE && 'alternativeShadow';

  return (
    <PagepartBase backgroundColor={backgroundColor.color}>
      <div className="u-grid u-grid--2@lg u-grid--spacing-3">
        <JobAlertCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...recruiterBlock[0]}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...recruiterBlock[0].recruiter[0]}
          modifier={modifier}
        />

        <JobAlertCard
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...jobAlertBlock[0]}
          firstCta={{
            id: 'sign-me-up',
            title: jobAlertBlock[0].firstCtaText,
            internalLink: [
              {
                slug: `/vacatures/vacaturemelding${
                  jobAlertParameters ? `?${jobAlertParameters}` : ''
                }`,
              },
            ],
          }}
          modifier={modifier}
        />
      </div>
    </PagepartBase>
  );
}

JobAlertSection.propTypes = {
  recruiterBlock: PropTypes.arrayOf(recruiterBlockType),
  jobAlertBlock: PropTypes.arrayOf(jobAlertBlockType),
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
  jobAlertParameters: PropTypes.string,
};

export default JobAlertSection;
