import Button from '@atoms/Button/Button';
import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import FaqList from '@molecules/FaqList/FaqList';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PagepartContent from '@molecules/PagepartContent/PagepartContent';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './FaqSection.module.scss';

function FaqSection({
  title,
  subtitle,
  text,
  cta,
  faqList,
  firstQuestionOpen,
  backgroundColor,
}) {
  let ctaLink;

  if (cta?.internalLink[0]?.slug) {
    if (cta.internalLink[0].typename === 'HomePage') {
      ctaLink = '/';
    } else {
      ctaLink = cta.internalLink[0].slug;
    }
  } else if (cta?.externalLink) {
    ctaLink = cta.externalLink;
  } else if (cta?.file?.url) {
    ctaLink = `${cta.file.url}?cd=inline`;
  }

  return (
    <PagepartBase backgroundColor={backgroundColor.color} modifier="faqSection">
      <div
        className={classNames(styles.container, styles[backgroundColor.color])}
      >
        <PagepartContent
          title={title}
          subtitle={subtitle}
          text={text}
          extraClasses={styles.content}
        />

        <FaqList faqList={faqList} firstQuestionOpen={firstQuestionOpen} />

        {cta && (
          <Button
            href={ctaLink}
            label={cta?.title}
            extraClasses={styles.ctaButton}
          />
        )}
      </div>
    </PagepartBase>
  );
}

FaqSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  faqList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  firstQuestionOpen: PropTypes.bool,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default FaqSection;
