import ContactLink from '@atoms/ContactLink/ContactLink';
import { BG_COLORS, BG_WHITE } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import PagepartCta from '@molecules/PagepartContent/PagepartCta';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ContactTilesSection.module.scss';

function ContactTile({ tile }) {
  const { title, text, phone, email, cta } = tile;
  return (
    <div className={styles.card}>
      <h3 className={styles.title}>{title}</h3>
      {text && <p className={styles.text}>{text}</p>}
      {(phone || email) && (
        <ul className={classNames(styles.links, 'u-list-clean', 'u-mb-0')}>
          {[phone[0], email[0]]
            .filter(el => !!el)
            .map(linkItem => (
              <li key={linkItem.id}>
                <ContactLink link={linkItem} />
              </li>
            ))}
        </ul>
      )}
      <PagepartCta cta={cta} extraClasses={styles.button} size="small" />
    </div>
  );
}

const tileType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  phone: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      phone: PropTypes.string,
      isWhatsapp: PropTypes.bool,
    })
  ),
  email: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  cta: ctaType,
});

ContactTile.propTypes = {
  tile: tileType,
};

function ContactTilesSection({ tiles, backgroundColor }) {
  const modifier = backgroundColor.color === BG_WHITE && 'alternativeShadow';

  return (
    <PagepartBase
      backgroundColor={backgroundColor.color}
      modifier="contactTilesSection"
    >
      <div
        className={classNames(styles.container, modifier && styles[modifier])}
      >
        {tiles.map(tile => (
          <ContactTile key={tile.id} tile={tile} />
        ))}
      </div>
    </PagepartBase>
  );
}

ContactTilesSection.propTypes = {
  tiles: PropTypes.arrayOf(tileType).isRequired,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default ContactTilesSection;
