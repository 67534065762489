import { BG_COLORS } from '@constants';
import { ctaType } from '@lib/proptypes';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import DownloadIcon from '@svg/download.svg';
import PropTypes from 'prop-types';

function DownloadSection({ title, text, cta, backgroundColor }) {
  return (
    <PagepartBase
      title={title}
      text={text}
      cta={cta}
      CtaIcon={DownloadIcon}
      ctaAnimation={false}
      backgroundColor={backgroundColor.color}
    />
  );
}

DownloadSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  backgroundColor: PropTypes.shape({
    color: PropTypes.oneOf(BG_COLORS),
  }).isRequired,
};

export default DownloadSection;
