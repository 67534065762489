import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './BannerSection.module.scss';

function BannerSection({ title, subtitle, showTitleFirst }) {
  return (
    <PagepartBase backgroundColor="red">
      <div
        className={classNames(
          styles.container,
          !showTitleFirst && styles.showTitleSecond
        )}
      >
        <h2 className={styles.title}>{title}</h2>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      </div>
    </PagepartBase>
  );
}

BannerSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showTitleFirst: PropTypes.bool,
};

export default BannerSection;
