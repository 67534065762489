import { relatedNewsType } from '@lib/proptypes';
import Carousel from '@molecules/Carousel/Carousel';
import NewsItem from '@molecules/NewsItem/NewsItem';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import parseRelatedNews from '@utils/parseRelatedNews';
import useRelatedNews from '@utils/useRelatedNews';

function RelatedNewsSection({
  title,
  text,
  cta,
  categories,
  selection,
  modifier,
}) {
  const { relatedNews, hasRelatedNews } = useRelatedNews({
    categories,
    selection,
  });

  if (!hasRelatedNews && !selection?.length) {
    return null;
  }

  const { items } = parseRelatedNews({ ...relatedNews, selection, categories });

  return (
    <PagepartBase modifier={modifier}>
      <Carousel
        title={title}
        text={text}
        items={items}
        cta={cta}
        SlideItem={NewsItem}
      />
    </PagepartBase>
  );
}

RelatedNewsSection.propTypes = relatedNewsType;

export default RelatedNewsSection;
